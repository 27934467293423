import React, { useState } from "react";
import logoImg from "../../assets/img/BreathEasy.png";
import "../Header/Header.css";
import { Link, useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import * as FaIcons from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import axios from "axios";
import { errorToast } from "../../utils/ToasterUtils";
import moment from "moment";
import { GlobalConstants } from "../../utils/GlobalConstants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setInitGlobalMedFunc } from "../../redux/GlobalMedicineSlice";
import { deviceListLogout } from "../../redux/DeviceListSlice";
import { RefillMedicineFunc } from "../../redux/RefillMedicine";
import { medicinePopupFunc } from "../../redux/DashboardSlice";
import { MyMedicineFunc } from "../../redux/MyMedicineSlice";
import { doseTakenFunc } from "../../redux/DoseTakenData";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FirstName = sessionStorage.getItem("firstname");
  const LastName = sessionStorage.getItem("lastname");

  const [text_dashboard, setText_dashboard] = useState("Dashboard");
  const [text_mymedicine, setText_mymedicine] = useState("My Medicine");
  const [text_report, setText_report] = useState("Report");
  const [text_weather, setText_weather] = useState("Weather");
  const [text_contact_us, setText_contact_us] = useState("Contact Us");
  const [text_logout, setText_logout] = useState("Log out");
  const[text_lan,setText_lan] = useState("Language");
  const[text_eng,setText_eng] = useState("English");
  const[text_hin,setText_hin] = useState("Hindi");
  const[text_guj,setText_guj] = useState ("Gujarati");
  const[text_arabic,setText_arabic] = useState ("Arabic");
  const[text_chinese,setText_chinese] = useState ("Chinese");
  const[text_spanish,setText_spanish] = useState ("Spanish");
  const[text_port,setText_port] = useState ("Portuguese");
  const[text_french,setText_french] = useState ("French");


  const logoutHandler = () => {
    Cookie.remove("token");
    Cookie.remove("user_email");
    Cookie.remove("firstname");
    Cookie.remove("lastname");
    Cookie.remove("user_id");
    Cookie.remove("token_start_time");
    sessionStorage.clear();
    localStorage.clear();
    dispatch(setInitGlobalMedFunc());
    dispatch(deviceListLogout());
    dispatch(RefillMedicineFunc());
    dispatch(medicinePopupFunc());
    dispatch(MyMedicineFunc());
    dispatch(doseTakenFunc());
    navigate("/");
  };
  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("mySidenav").style.zIndex = "1000";
    // document.getElementById("mySidenav").style.height = "550px";
    //document.getElementById("header").style.marginLeft = "250px";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    //document.getElementById("header").style.marginLeft= "0";
  };
  let Dashboard,
    MyMedicine,
    Report,
    Weather,
    ContactUs = "headerText";
  const pageName = window.location.pathname.split("/");
  if (pageName[1] === "dashboard") {
    Dashboard = "active1";
    MyMedicine = "headerText";
    Report = "headerText";
    Weather = "headerText";
    ContactUs = "headerText";
  } else if (pageName[1] === "mymedicine") {
    Dashboard = "headerText";
    MyMedicine = "active1";
    Report = "headerText";
    Weather = "headerText";
    ContactUs = "headerText";
  } else if (pageName[1] === "report") {
    Dashboard = "headerText";
    MyMedicine = "headerText";
    Report = "active1";
    Weather = "headerText";
    ContactUs = "headerText";
  } else if (pageName[1] === "current") {
    Dashboard = "headerText";
    MyMedicine = "headerText";
    Report = "headerText";
    Weather = "active1";
    ContactUs = "headerText";
  } else if (pageName[1] === "contactus") {
    Dashboard = "headerText";
    MyMedicine = "headerText";
    Report = "headerText";
    Weather = "headerText";
    ContactUs = "active1";
  }
  const languageHandle = (e) => {
    const languageCode = e;
    console.log(languageCode);
    const request_start_at = performance.now();

    var apiUrl =
      GlobalConstants.Cdomain +
      "/api/breatheasy/v1/user/defaultlanguages/selectdefaultlanguage?languageCode=" +
      languageCode;

    let headerConfig = {
      headers: {
        accept: "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    };

    axios
      .get(apiUrl, headerConfig)
      .then(function (response) {
        const request_end_at = performance.now();
        const request_duration = request_end_at - request_start_at;
        var res = response.data.data[0].defaultlanguagescreenlist;
        let xml = "<resources>";

        for (let i = 0; i < res.length; i++) {
          let d = res[i];
          for (let j = 0; j < d.defaultscreenfieldslist.length; j++) {
            var kv = d.defaultscreenfieldslist[j];

            var s = `<string name="${kv.fieldKey}"> ${kv.fieldValue}</string>`;
            xml += s;
          }
        }
        xml += "</resources>";
        GlobalConstants.appXml = xml;
        localStorage.setItem(GlobalConstants.session_lang_xml, xml);
        localStorage.setItem(
          GlobalConstants.session_default_language,
          languageCode
        );
        console.log(languageCode, "-----------");
        try {
          SetLanguageText();
        } catch (err) {
          errorToast(err);
        }
        if (response.status === 200) {
          console.log(
            "ID:06101=> " +
              moment.utc(request_duration).format("ss.ms") +
              " Seconds"
          );
          window.location.reload();
        }
      })
      .catch(function (error) {
        errorToast(error.massage);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("token");
          Cookie.remove("user_email");
          Cookie.remove("firstname");
          Cookie.remove("lastname");
          Cookie.remove("user_id");
          Cookie.remove("token_start_time");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      })
      .then(function () {
        // always executed
      });
  };

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_dashboard(
        xpath.select1("/resources/string[@name='text_dashboard']", doc)
          .firstChild.data
      );
      setText_mymedicine(
        xpath.select1("/resources/string[@name='text_mymedicine']", doc)
          .firstChild.data
      );
      setText_report(
        xpath.select1("/resources/string[@name='text_report']", doc).firstChild
          .data
      );
      setText_weather(
        xpath.select1("/resources/string[@name='text_weather']", doc).firstChild
          .data
      );
      setText_contact_us(
        xpath.select1("/resources/string[@name='text_contact_us']", doc)
          .firstChild.data
      );
      setText_logout(
        xpath.select1("/resources/string[@name='text_logout']", doc).firstChild
          .data
      );
      // setText_eng(
      //   xpath.select1("/resources/string[@name='text_eng']", doc).firstChild
      //     .data
      // );
      // setText_hin(
      //   xpath.select1("/resources/string[@name='text_hin']", doc).firstChild
      //     .data
      // );
      // setText_guj(
      //   xpath.select1("/resources/string[@name='text_guj']", doc).firstChild
      //     .data
      // );
      // setText_arabic(
      //   xpath.select1("/resources/string[@name='text_arabic']", doc).firstChild
      //     .data
      // );
      // setText_chinese(
      //   xpath.select1("/resources/string[@name='text_chinese']", doc).firstChild
      //     .data
      // );
      // setText_spanish(
      //   xpath.select1("/resources/string[@name='text_spanish']", doc).firstChild
      //     .data
      // );
      // setText_port(
      //   xpath.select1("/resources/string[@name='text_port']", doc).firstChild
      //     .data
      // );
      // setText_french(
      //   xpath.select1("/resources/string[@name='text_french']", doc).firstChild
      //     .data
      // );
      setText_lan(
        xpath.select1("/resources/string[@name='text_lan']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  return (
    <>
      <header>
        <div className="head_container headerbg">
          <div className="logo">
            <Link to={"/dashboard"}>
              <img src={logoImg} alt="" className="logoImgSty" />
            </Link>
          </div>
          <div className="menu" id="myTopnav">
            <ul>
              <Link to="#" onClick={openNav}>
                <FaIcons.FaBars className="icon" />{" "}
              </Link>
              <li id={Dashboard}>
                <Link to={"/dashboard"} className="nav-links">
                  {text_dashboard}
                </Link>
              </li>
              <li id={MyMedicine}>
                <Link to={"/mymedicine"} className="nav-links">
                  {text_mymedicine}
                </Link>
              </li>
              <li id={Report}>
                <Link to={"/report"} className="nav-links">
                  {text_report}
                </Link>
              </li>
              <li id={Weather}>
                <Link to={"/current/weather"} className="nav-links">
                  {text_weather}
                </Link>
              </li>
              <li id={ContactUs}>
                <Link to={"/contactus"} className="nav-links">
                  {text_contact_us}
                </Link>
              </li>
              <li>
                <select
                  className="languageinput"
                  onChange={(e) => languageHandle(e.target.value)}
                  defaultValue={localStorage.getItem("default_language")}
                >
                  <option disabled>{text_lan}</option>
                  <option value="en" defaultValue className="languageselect">
                    {text_eng}
                  </option>
                  <option value="hi" className="languageselect">
                    {text_hin}
                  </option>
                  <option value="guj" className="languageselect">
                    {text_guj}
                  </option>
                  <option value="ar" className="languageselect">
                    {text_arabic}
                  </option>
                  <option value="zho_Mandarin" className="languageselect">
                    {text_chinese}
                  </option>
                  <option value="es" className="languageselect">
                    {text_spanish}
                  </option>
                  <option value="pt" className="languageselect">
                  {text_port}
                  </option>
                  <option value="fr" className="languageselect">
                  {text_french}
                  </option>
                </select>
              </li>
              <li>
                <Link
                  className="btn  dropdown-toggle headerbtn nav-links"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {FirstName + " " + LastName}
                </Link>
                <ul
                  className="dropdown-menu textheaderdropdown"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="dropdown_item">
                    <Link
                      className="link_dropdown text-center"
                      to="/"
                      onClick={logoutHandler}
                    >
                      {text_logout}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div id="mySidenav" className="sidenav">
        <Link to="#" className="closebtn" onClick={closeNav}>
          <MdOutlineClose className="closemenubar" />
        </Link>
        <Link to={"/dashboard"} className="nav-links">
          {text_dashboard}
        </Link>
        <Link to={"/mymedicine"} className="nav-links">
          {text_mymedicine}
        </Link>
        <Link to={"/report"} className="nav-links">
          {text_report}
        </Link>
        <Link to={"/current/weather"} className="nav-links">
          {text_weather}
        </Link>
        <Link to={"/contactus"} className="nav-links">
          {text_contact_us}
        </Link>
        <div className="px-4">
          <select
            className="languageinput"
            onChange={(e) => languageHandle(e.target.value)}
            defaultValue={localStorage.getItem("default_language")}
          >
            <option disabled>Language</option>
            <option value="en" defaultValue className="languageselect">
              English
            </option>
            <option value="hi" className="languageselect">
              Hindi
            </option>
            <option value="guj" className="languageselect">
              Gujarati
            </option>
            <option value="ar" className="languageselect">
              Arabic
            </option>
            <option value="zho_Mandarin" className="languageselect">
              Chinese
            </option>
            <option value="es" className="languageselect">
              Spanish
            </option>
          </select>
        </div>
        <div className="dropdown">
          <Link
            className="dropdown-toggle nav-links"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {FirstName + " " + LastName}
          </Link>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li className="dropdown_item">
              <Link
                className="link_dropdown text-center"
                to="/"
                onClick={logoutHandler}
                style={{ fontSize: "18px", padding: "0px" }}
              >
                {text_logout}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
